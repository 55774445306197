<template>
  <div class="index-container">
    <div v-if="loaded" style="width:100%;height:100%;">

      <div v-if="!isMyPage && socials.length == 0" class="no-found-content">
        <img src="../assets/images/logo.png" alt="" class="logo">
        <img v-if="userInfo.avatar && userInfo.avatar.length > 0" :src="userInfo.avatar" alt="" class="avatar">
        <img v-else src="../assets/images/default_avatar.png" alt="" class="avatar">
        <div class="name" v-if="userInfo.nickname && userInfo.nickname.length > 0" style="color:#fff">
          {{ userInfo.nickname }}</div>
        <div class="name" v-else>神秘的昵称</div>
        <div class="desc">ta的主页还在建设中<br>暂时无法访问</div>
        <a class="link_btn0" style="width:1.8rem;margin-top: 0.32rem;" @click="home">返回到首页</a>
        <div class="second">{{ seconds }}s 后自动返回到首页</div>
        <div class="copyright">2022 © LINKBaeBae 链接吧，宝贝！</div>
      </div>
      <index-content ref="indexContent" v-else :userInfo="userInfo" :theme="theme" :socials="socials"
        :id="userInfo.theme_style_id" :guideStep="guide.step">
        <div v-if="guide.show" class="guide-bg" :style="{ zIndex: guide.step > 0 ? 2 : 1 }">
          <div class="guide-detail" v-if="guide.step < 2"
            :style="{ height: guide.step == 0 ? guide.bottom + 'px' : guide.bottom2 + 'px' }">
            <div class="guide-note-bg" v-if="guide.type == 0">快去添加我的第1个链接，让<br>世界认识我！添加后解锁「切<br>换主题」、「预览&分享」等<br>更多功能
              <div class="guide-btn" @click="updateGuideData">完成</div>
            </div>
            <div class="guide-note-bg" v-if="guide.type == 1 && guide.step == 0">这是我刚添加的第1个链接，<br>点击跳转查看；如需调整，仍<br>可继续编辑
              <div class="guide-btn" @click="guideNext">下一步</div>
            </div>
            <div class="guide-note-bg" v-if="guide.type == 1 && guide.step == 1">添加更多链接，让世界更好地<br>了解我！
              <div class="guide-btn" @click="guideNext">下一步</div>
            </div>
            <div class="guide-note-bg" v-if="guide.type == 2">长按激活，上下拖拽调整排序
              <div class="guide-btn" @click="updateGuideData">完成</div>
            </div>

            <img src="../assets/images/arrow_corner.png" style="width:42px;height:21px;" alt="" class="arrow">
          </div>
          <div class="guide-top-shadow" v-if="guide.step == 3"></div>
          <div v-if="guide.step == 2" class="guide-menu">

            <div class="close icon-close" style="color:#fff">

            </div>
            <div style="height:0.84rem"></div>
            <a class="menu_item">
              <SvgIcon name="theme" size="36" />
              <div class="menu_title">主题风格</div>
            </a>
            <a class="menu_item">
              <SvgIcon name="base_info" size="36" />
              <div class="menu_title">基本信息</div>
            </a>
            <a class="menu_item">
              <SvgIcon name="safe" size="36" />
              <div class="menu_title">账号安全</div>
            </a>
            <a class="menu_item">
              <SvgIcon name="history" size="36" />
              <div class="menu_title">访问历史</div>
            </a>
            <div class="expand"></div>
            <a class="logout">
              退出登录
            </a>
          </div>
          <div class="step2-detail" v-if="guide.step == 2">
            <img src="../assets/images/arrow_left.png" style="width:21px;height:42px;" alt="" class="arrow2">
            <div class="guide-note-bg">这里汇集了更多主题风<br>格，快去探索并装点我的<br>主页吧！未来将会支持个<br>性化样式，敬请期待～<div class="guide-btn"
                style="width:2.2rem" @click="guideNext">下一步</div>
            </div>
          </div>
          <div class="step3-detail" v-if="guide.step == 3">
            <img src="../assets/images/arrow_corner.png" style="width:42px;height:21px;" alt="" class="arrow3">
            <div class="guide-note-bg">预览主页效果，所见即是<br>我确认后，分享至会话或<br>群聊，是时候让世界认识<br>我了！<div class="guide-btn"
                style="width:2.2rem" @click="guideNext">完成</div>
            </div>
          </div>
          <div style="flex:1"></div>
        </div>
      </index-content>

      <!-- <div class="guide-close-btn-layer" v-if="guide.show">
        <div style="flex:1"></div>
        <a v-if="guide.type == 0 || guide.type == 2" class="link_btn0 footer" style="width:1.72rem;height:0.52rem"
          @click="updateGuideData">完成</a>
        <a v-if="guide.type == 1" class="link_btn0 footer" style="width:1.72rem;height:0.52rem;" @click="guideNext">{{
            guide.step == 3 ? '完成' : `开始探索 ${guide.step + 2}/4`
        }}</a>
        <div style="height:0.3rem"></div>
      </div> -->
    </div>
    <div v-else class="loading-bg">
      <img src="../assets/images/toast_loading.png" class="loading" alt="">
      <div class="loading-text">页面加载中…</div>
    </div>

    <div class="welcome-guide" v-if="welcomeGuide">
      <div class="welcome-content">
        <img src="../assets/images/welcome_guide.png" alt="" class="welcome-top-img">
        <div class="welcome-tip">欢迎加入</div>
        <img src="../assets/images/logo.png" class="welcome-logo" alt="">
        <div class="welcome-desc">将自己的各平台汇聚于此，<br>丰富的主题彰显你独特的个性，分享给你<br>的好友，全网找你不迷路</div>
        <a class="link_btn0" style="width:2.16rem" @click="closeWelcomeGuide">开始探索</a>
      </div>
    </div>

  </div>
</template>

<script>
import html2canvas from 'html2canvas';
import { get, post } from '../config/http';
import api from '../config/api';
import $ from 'jquery';
import canvg from 'canvg';
import { Container, Draggable } from 'vue-smooth-dnd';
import SvgIcon from '../components/SvgIcon.vue';
import Menu from '../components/menu.vue';
import IndexContent from './index_content.vue';

export default {
  components: {
    Container,
    Draggable,
    SvgIcon,
    Menu,
    IndexContent
  },
  name: 'index',
  props: {
    id: String,
  },
  data() {
    let htmlW = document.documentElement.clientWidth;
    let W = htmlW > 750 ? 750 : htmlW;
    let scaleScreen = W / 375;
    var isMyPage = false;
    if (global.hasLogin) {

      var uid = this.$route.params.id//this.$route.query.uid;

      isMyPage = true
      if (uid != undefined && uid != global.userInfo.id) {
        isMyPage = false;
      }
    }
    return {
      userInfo: null,
      theme: null,
      width: scaleScreen * 375,
      scaleScreen: scaleScreen,
      items: [],
      showMenu: false,
      socials: [],
      isMyPage: isMyPage,
      loaded: false,
      seconds: 3,
      welcomeGuide: false,
      guide: {
        show: false,
        type: 1,
        step: 0,
        bottom: 0,
        bottom2: 0
      },
    };
  },

  metaInfo() {
    return {
      title: 'LinkBaeBae 链接吧，宝贝！',
      meta: [
        { property: 'og:title', content: 'LinkBaeBae 链接吧，宝贝！' },
        { property: 'og:type', content: 'article' },
        {

          property: 'og:image', content: 'https://m.link.dev.liveplus.fun/share_cover.jpg'
        }
      ]
    }
  },

  activated() {
    var _this = this
    setTimeout(()=>{
      _this.$refs.indexContent&&_this.$refs.indexContent.refresh()
    },200)
    
  },

  mounted() {
    //2f60a3f5db7cf2a8dced1dc33ae01049
    global.index = this
    global.index_alive = true
    document.title = 'LinkBaeBae 链接吧，宝贝！'
    // document.images.oImage.src = 'https://link-dev.oss-cn-beijing.aliyuncs.com/users…1737-4517-91c1-5d7df6c2f440_20220830190856_277png'
    var main = document.getElementById('main');
    if (main) {
      main.style.height = document.body.clientHeight + 'px'
      // alert('aaa')
    }
    else {
      // alert('sss')
    }
    document.body.style.overflow = 'hidden';
    this.loadData();

    if (window.history.length == 1) {
      localStorage.setItem('clientHeight', document.documentElement.clientHeight);
      global.clientHeight = localStorage.getItem('clientHeight')
    }

  },

  methods: {
    drawShare() {

    },
    close() {
      this.showMenu = false;
    },
    home() {
      clearInterval(this.timer)
      this.$router.back()
    },
    refresh() {
      this.loadData();
    },

    loadData() {
      var _this = this;
      //864d43e21a3a1d9619f8a9a455ccac96
      if (!this.isMyPage) {
        var uid = this.$route.params.id;
        if (!this.id) {
          this.id = '864d43e21a3a1d9619f8a9a455ccac96';
        }
        if (!uid) {
          uid = this.id//'2f60a3f5db7cf2a8dced1dc33ae01049';
        }
        Promise.all([get(`users/${uid}/info`), get(`users/${uid}/styles`)]).then((list) => {
          _this.userInfo = list[0];
          var json = list[1];
          this.parseJson(json);
          _this.socials = _this.userInfo.socials
          _this.theme = json;
          _this.loaded = true
          _this.updateTime()

        });
        return
      }

      Promise.all([get(api.userSocials), get(api.userStyles), get(api.socials, { all: true }), get(api.frontDatas, { codes: 'guide_first_link,guide_more,guide_drag,guide_welcome,profile_completed_step' })]).then((list) => {
        _this.userInfo = list[0];
        var json = list[1];
        this.parseJson(json);
        _this.theme = json;
        global.userInfo = _this.userInfo;
        global.theme = json;
        _this.socials = list[2].data
        _this.loaded = true

        setTimeout(() => {
          _this.checkWelcomeGuide(list[3])
        }, 100)

        var step = parseFloat(list[3][list[3].length - 1].value)
        if (this.userInfo.socials.length == 0 && step >= 1.2) {
          _this.resetProfileComplete()
        }
      });
    },

    resetProfileComplete() {
      this.$post(this.$api.frontData, {
        code: 'profile_completed_step',
        value: 1.2
      }).then(data => {
      })
    },
    updateTime() {
      if (this.socials.length == 0) {
        var _this = this
        this.timer = setInterval(() => {
          this.seconds = this.seconds - 1;
          if (this.seconds < 0) {
            clearInterval(_this.timer)
            this.$router.back()
          }
        }, 1000)
      }
    },

    guideNext() {
      if (this.guide.type == 1) {
        var step = this.guide.step;
        step++;
        if (step >= 4) {
          this.updateGuideData()
        }
        this.guide.step = step
      }
    },
    checkWelcomeGuide(data) {
      this.guideData = data;
      var obj = null
      data.map(item => {
        if (item.code == 'guide_welcome') {
          obj = item
        }
      })

      if (!obj || obj.value != '1') {
        this.welcomeGuide = true
      }
      else {
        this.checkGuideData(data, 300);
      }
    },

    closeWelcomeGuide() {
      this.welcomeGuide = false
      post(api.frontData, {
        code: 'guide_welcome',
        value: '1'
      })
      this.checkGuideData(this.guideData, 0);
    },

    checkGuideData(data, time) {
      var obj = null
      var _this = this
      if (this.userInfo.socials.length == 0) {

        data.map(item => {
          if (item.code == 'guide_first_link') {
            obj = item
          }
        })
        if (!obj || obj.value != '1') {

          // console.log(document.getElementById('add-item').offsetTop)
          this.$refs.indexContent && this.$refs.indexContent.hidenExpand()
          setTimeout(() => {
            _this.guide = {
              show: true,
              bottom: document.getElementById('add-item').offsetTop,
              bottom2: document.getElementById('add-item').offsetTop,
              type: 0,
              step: 1
            }
          }, time)

        }
      }
      else if (this.userInfo.socials.length == 1) {
        data.map(item => {
          if (item.code == 'guide_more') {
            obj = item
          }
        })
        if (!obj || obj.value != '1') {
          this.$refs.indexContent && this.$refs.indexContent.hidenExpand()
          setTimeout(() => {
            _this.guide = {
              show: true,
              bottom: document.getElementById('first-item').offsetTop,
              bottom2: document.getElementById('add-item').offsetTop,
              type: 1,
              step: 0
            }
          }, time)

        }
      }
      else if (this.userInfo.socials.length == 2) {
        data.map(item => {
          if (item.code == 'guide_drag') {
            obj = item
          }
        })
        if (!obj || obj.value != '1') {
          this.$refs.indexContent && this.$refs.indexContent.hidenExpand()
          setTimeout(() => {
            _this.guide = {
              show: true,
              bottom: document.getElementById('first-item').offsetTop,
              type: 2,
              step: 0
            }
          }, time)

        }
      }
    },

    updateGuideData() {
      this.guide.show = false
      var code = 'guide_first_link'
      if (this.guide.type == 1) {
        code = 'guide_more'
      }
      else if (this.guide.type == 2) {
        code = 'guide_drag'
      }
      post(api.frontData, {
        code: code,
        value: '1'
      })
    },

    parseJson(jsonObj) {
      for (var key in jsonObj) {
        var element = jsonObj[key];
        // if ((element.length > 0 && typeof element == 'object') || typeof element == 'object') {
        if (typeof element == 'object' && !Array.isArray(element)) {
          this.parseJson(element);
        } else if (element.length > 0 && typeof element == 'string') {
          if (element.indexOf('#') != -1) {
            if (element.indexOf('#') != 0) {
              jsonObj[key] = element.replaceAll(/#([\w]{2})(\w{6})/ig, (match, p1, p2) => {
                return '#' + p2 + p1;
              })
            }
            else {
              jsonObj[key] = '#' + element.substring(3, 9) + element.substring(1, 3);
            }

          }
        }
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='scss' scoped>
* {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.welcome-guide {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(5px);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;

  .welcome-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 3.14rem;
    height: 4.14rem;
    background: #131314;
    box-shadow: 0px 24px 24px -12px rgba(0, 0, 0, 0.4);
    border-radius: 0.32rem;
    position: relative;
  }

  .welcome-top-img {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    height: 1.3rem;
    object-fit: cover;
  }

  .welcome-tip {
    position: relative;
    line-height: 0.33rem;
    font-size: 0.24rem;
    color: #FFF700FF;
    font-weight: bold;
    margin-top: 0.4rem;
  }

  .welcome-desc {
    position: relative;
    color: #A5A5ADFF;
    font-size: 0.14rem;
    line-height: 0.24rem;
    margin-bottom: 0.36rem;
  }

  .welcome-logo {
    position: relative;
    margin-top: 0.16rem;
    width: 2.16rem;
    height: 0.83rem;
    margin-bottom: 0.24rem;
  }
}

.index-container {
  width: 100%;
  height: 100%;
  background: #131314;
}

.no-found-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: relative;

  .logo {
    width: 1.67rem;
    height: 0.64rem;
    margin-top: 0.32rem;
  }

  .avatar {
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 0.6rem;
    object-fit: cover;
    margin-top: 0.76rem;
  }

  .name {
    margin-top: 0.1rem;
    line-height: 0.44rem;
    font-size: 0.32rem;
    color: #FFFFFF66;
    font-weight: bold;
  }

  .desc {
    margin-top: 0.08rem;
    font-size: 0.14rem;
    line-height: 0.24rem;
    color: #fff;
  }

  .second {
    margin-top: 0.28rem;
    font-size: 0.12rem;
    line-height: 0.24rem;
    color: #FFFFFF66;
  }

  .copyright {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0.44rem;
    color: #FFFFFF33;
    font-size: 0.12rem;
    text-align: center;
  }
}

.loading-bg {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .loading {
    width: 0.48rem;
    height: 0.48rem;
    animation: loading 1s 0s linear infinite;
  }

  .loading-text {
    margin-top: 0.1rem;
    font-size: 0.14rem;
    color: #fff;
  }

  @keyframes loading {

    // 0%{
    //   transform: rotate(0);
    // }
    100% {
      transform: rotate(360deg);
    }
  }
}

.guide-bg {
  background: #000000BF;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  .guide-top-shadow {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    height: 0.72rem;
    background: linear-gradient(270deg, #FFF700 0%, rgba(255, 247, 0, 0) 100%);
  }

  .guide-detail {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;


  }

  .guide-note-bg {
    background-color: #0000FFFF;
    border-radius: 0.2rem;
    padding: 0.2rem 0.28rem;
    text-align: left;
    font-size: 0.2rem;
    color: #FFFFFF;
    line-height: 0.36rem;
    font-weight: bold;
  }

  .guide-btn {
    position: relative;
    color: #fff;
    width: 2.6rem;
    height: 0.44rem;
    background-color: #FFFFFF4D;
    border-radius: 0.22rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.1rem;
    font-size: 0.16rem;
  }

  .step2-detail {
    position: absolute;
    top: 0.15rem;
    left: 0.6rem;
    display: flex;
    flex-direction: row;
    align-items: center;

    .arrow2 {}
  }

  .step3-detail {
    position: absolute;
    top: 0.45rem;
    right: 0.08rem;

    .arrow3 {
      transform: rotateX(180deg);
      margin-bottom: -8px;
      margin-left: 1.4rem;
    }
  }

  .footer {
    margin-bottom: 0.66rem;
  }

  .guide-menu {
    width: 1.2rem;
    height: 100%;
    background: #000000E6;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    .close {
      position: absolute;
      left: 0.24rem;
      top: 0.24rem;
    }

    .menu_item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 0.36rem;

      .menu_title {
        color: #fff;
        margin-top: 0.08rem;
        font-size: 0.14rem;
        line-height: 0.2rem;
      }
    }

    .logout {
      color: #fff;
      font-size: 0.14rem;
      margin-bottom: 0.5rem;
    }
  }
}

.guide-close-btn-layer {
  z-index: 3;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>